import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

import { ProductPreview } from 'domain/types';
import { useLinkSwipe } from 'utils/hooks/useLinkSwipe';
import CustomA from 'components/ui/links/CustomA';
import PriceText from 'components/ui/textFormaters/PriceText';

interface Props {
  product: ProductPreview;
  containerClassName?: string;
  size?: 'small' | 'large';
}

const FeaturedProductCard: React.FC<Props> = ({
  product: {
    slug,
    mainImage: { url },
    name,
    price,
    discount,
    category: { slug: categorySlug }
  },
  size = 'large'
}): JSX.Element => {
  const classes = useStyles();

  const { handleMouseDown, handleClick } = useLinkSwipe();

  return (
    <Link href={`/shop/${categorySlug}/${slug}`} passHref>
      <CustomA>
        <Box
          className={classes.cardContainer}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          component="article"
        >
          <Image src={url} quality={100} width={284} height={284} />

          <Box className={classes.content}>
            <Typography
              variant="h3"
              component="h3"
              className={classnames(classes.title, size === 'small' && classes.titleSmall)}
            >
              {name}
            </Typography>

            <Typography
              variant="h5"
              component="p"
              color="textSecondary"
              className={classnames(size === 'small' && classes.priceSmall)}
            >
              <PriceText price={price} discount={discount} />
            </Typography>
          </Box>
        </Box>
      </CustomA>
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%'
  },
  content: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  titleSmall: {
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  },
  priceSmall: {
    fontSize: '0.875rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  }
}));

export default FeaturedProductCard;
