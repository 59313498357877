import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import TextContent from './TextContent';

interface ItemContentProps {
  title: string;
  subtitle?: string;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}

const ItemContent: React.FC<ItemContentProps> = ({
  title,
  subtitle,
  textAlign = 'center'
}: ItemContentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.content)} style={{ textAlign }}>
      <TextContent title={title} subtitle={subtitle} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'initial',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  linkedContent: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
      transition: 'opacity 300ms',
      textDecoration: 'none'
    }
  }
}));

export default ItemContent;
