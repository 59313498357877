import { useState } from 'react';

export const useLinkSwipe = () => {
  const [mouseDownTime, setMouseDownTime] = useState<number | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setMouseDownTime(Date.now());
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (typeof mouseDownTime !== 'number' || Date.now() - mouseDownTime > 300) {
      e.preventDefault();
    }
  };

  return {
    handleMouseDown,
    handleClick
  };
};
