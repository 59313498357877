import React from 'react';
import Image from 'next/image';
import { Box, makeStyles, Link as MuiLink } from '@material-ui/core';
import classnames from 'classnames';
import Link from 'next/link';

import ItemContent from './ItemContent';
import { HeroSlide } from 'domain/types';
import { useLinkSwipe } from 'utils/hooks/useLinkSwipe';

const LinkComponent: React.FC<{ children: React.ReactNode; link?: string }> = ({
  children,
  link
}): JSX.Element => {
  const { handleClick, handleMouseDown } = useLinkSwipe();
  const classes = useStyles();

  if (!link) return <>{children}</>;

  return (
    <Link href={link} passHref>
      <MuiLink
        className={classes.linkedContent}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
      >
        {children}
      </MuiLink>
    </Link>
  );
};

interface HeroSliderItemProps {
  item: HeroSlide;
}

const HeroSliderItem: React.FC<HeroSliderItemProps> = ({
  item: { image, title, subtitle, position = 'center', link, textAlign }
}: HeroSliderItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <LinkComponent link={link}>
      <Box component="article" className={classes.container}>
        {title && (
          <Box className={classnames(classes.contentContainer, classes[position])}>
            <ItemContent title={title} subtitle={subtitle} textAlign={textAlign} />
          </Box>
        )}

        <Box className={classes.imageContainer}>
          <Image
            src={image.url}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={100}
          />
        </Box>
      </Box>
    </LinkComponent>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '55vw',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: '55vh'
    },
    [theme.breakpoints.up('lg')]: {
      height: '65vh'
    },
    [theme.breakpoints.up('xl')]: {
      height: '70vh'
    }
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: -1
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3.5)
  },
  content: {
    textAlign: 'initial',
    padding: theme.spacing(2)
  },
  linkedContent: {
    '&:hover': {
      textDecoration: 'none'
    },
    '&:hover h1': {
      opacity: 0.7,
      transition: 'opacity 300ms',
      textDecoration: 'none'
    },
    '&:hover h2': {
      opacity: 0.7,
      transition: 'opacity 300ms',
      textDecoration: 'none'
    }
  },
  ['top-left']: {
    justifyContent: 'flex-start'
  },
  ['top-center']: {
    justifyContent: 'center'
  },
  ['top-right']: {
    justifyContent: 'flex-end'
  },
  ['center-left']: {
    alignItems: 'center'
  },
  ['center']: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  ['center-right']: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  ['bottom-left']: {
    alignItems: 'flex-end'
  },
  ['bottom-center']: {
    justifyContent: 'center'
  },
  ['bottom-right']: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
}));

export default HeroSliderItem;
