import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface CarouselProps {
  children: React.ReactNode;
  settings: Settings;
}

// eslint-disable-next-line no-unused-vars
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => {
  if (props.className.includes('slick-disabled')) return <></>;

  return <span {...props}>{children}</span>;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  settings,
  ...props
}: CarouselProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Slider
      arrows={false}
      prevArrow={
        <SlickButtonFix>
          <IconButton classes={{ root: classes.arrow }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        </SlickButtonFix>
      }
      nextArrow={
        <SlickButtonFix>
          <IconButton classes={{ root: classes.arrow }}>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </SlickButtonFix>
      }
      {...settings}
      {...props}
      draggable
      dotsClass={classes.dots}
    >
      {children}
    </Slider>
  );
};

const useStyles = makeStyles((theme) => ({
  dots: {
    position: 'absolute',
    bottom: theme.spacing(1),
    padding: 0,
    margin: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex !important',
    gap: 6,
    listStyle: 'none',
    ['& .slick-active > button']: {
      backgroundColor: theme.palette.common.white
    },
    ['& > li > button']: {
      cursor: 'pointer',
      borderWidth: 2,
      borderColor: theme.palette.common.white,
      borderStyle: 'solid',
      borderRadius: '50%',
      background: 'transparent',
      boxShadow: '0px 0px 0px transparent',
      textShadow: '0px 0px 0px transparent',
      fontSize: 1,
      height: 12,
      width: 12,
      color: 'transparent',
      padding: 0
    },
    [theme.breakpoints.up('sm')]: {
      bottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(2.5)
    }
  },
  arrow: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '@global': {
    '.slick-next': {
      position: 'absolute',
      right: -15,
      top: '50%',
      [theme.breakpoints.up('lg')]: {
        right: -20
      },
      [theme.breakpoints.up('xl')]: {
        right: -52
      }
    },
    '.slick-prev': {
      position: 'absolute',
      left: -15,
      top: '50%',
      zIndex: 1,
      [theme.breakpoints.up('lg')]: {
        left: -20
      },
      [theme.breakpoints.up('xl')]: {
        left: -52
      }
    }
  }
}));

export default Carousel;
