import React from 'react';
import Image from 'next/image';

interface Props {
  src: string;
  containerClassName?: string;
  title?: string;
}

const SquareImage: React.FC<Props> = ({ src, containerClassName = '', title }): JSX.Element => {
  return (
    <div
      className={containerClassName}
      style={{
        width: '100%',
        position: 'relative',
        height: 0,
        paddingBottom: '100%'
      }}
    >
      <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
        <Image src={src} quality={100} layout="fill" objectFit="cover" title={title} alt={title} />
      </div>
    </div>
  );
};

export default SquareImage;
