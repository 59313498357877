import { Container } from '@material-ui/core';

import { HeroSlide, Category, PageMeta, ProductPreview } from 'domain/types';
import {
  CategoriesRepo,
  ProductPreviewsRepo,
  HeroSlidesRepo,
  PageMetaRepo
} from 'domain/repositories';
import HeroSlider from 'components/home/HeroSlider/HeroSlider';
import Layout from 'components/layout/Layout';
import FeaturedProducts from 'components/product/FeaturedProducts/FeaturedProducts';
import CategoryTiles from 'components/home/CategoryTiles/CategoryTiles';
import HomeBlock from 'components/home/HomeBlock';
import Features from 'components/home/Features/Features';
import { featuredProductsToProductPreviews } from 'utils/helpers/products/featuredProductsToProductPreviews';
import { REVALIDATES } from 'domain/constants';

interface HomeProps {
  heroSlides: Array<HeroSlide>;
  featuredProducts: ProductPreview[];
  categories: Array<Category>;
  pageMeta: PageMeta;
}

const Home: React.FC<HomeProps> = ({
  heroSlides,
  featuredProducts,
  categories,
  pageMeta
}): JSX.Element => {
  return (
    <Layout pageMeta={pageMeta}>
      <HeroSlider heroSlides={heroSlides} />

      <Container>
        <HomeBlock title="Новинки" dense>
          <FeaturedProducts products={featuredProducts} size="small" />
        </HomeBlock>

        <HomeBlock title="Магазин">
          <CategoryTiles categories={categories} />
        </HomeBlock>

        <Features />
      </Container>
    </Layout>
  );
};

export const getStaticProps = async () => {
  const [heroSlides, featuredProductsWithColorCollection, categories, pageMeta] = await Promise.all(
    [
      HeroSlidesRepo.get(),
      ProductPreviewsRepo.getFeaturedProductsWithColors(),
      CategoriesRepo.get(),
      PageMetaRepo.getBySlug('index')
    ]
  );

  const featuredProducts = featuredProductsToProductPreviews(featuredProductsWithColorCollection);

  return {
    props: {
      heroSlides,
      featuredProducts,
      categories,
      pageMeta
    },
    revalidate: REVALIDATES.index
  };
};

export default Home;
