import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import FeatureItem from './FeatureItem';
import giftBoxIcon from 'public/icons/gift-box.svg';
import notebookIcon from 'public/icons/notebook.svg';
import truckIcon from 'public/icons/truck.svg';

const Features: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FeatureItem
        title="Подарункова упаковка"
        subtitle="для кожного замовлення"
        hasDivider
        icon={giftBoxIcon}
      />

      <FeatureItem
        title="Безкоштовна доставка"
        subtitle="від 1500 грн"
        hasDivider
        icon={truckIcon}
      />

      <FeatureItem
        title="Корпоративні замовлення"
        subtitle="брендованої продукції"
        icon={notebookIcon}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gap: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  }
}));

export default Features;
