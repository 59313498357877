import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { Category } from 'domain/types';
import CategoryTileItem from './CategoryTileItem';

interface CategoryTilesProps {
  categories: Array<Category>;
}

const CategoryTiles: React.FC<CategoryTilesProps> = ({ categories }): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {categories.map((category) => (
        <CategoryTileItem category={category} key={category.sys.id} />
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    [theme.breakpoints.up('sm')]: {
      gap: `${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  }
}));

export default CategoryTiles;
