import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Link from 'next/link';
import classnames from 'classnames';
import Image from 'next/image';

import { Category } from 'domain/types';
import CustomA from 'components/ui/links/CustomA';

interface CategoryTileProps {
  category: Category;
}

const CategoryTileItem: React.FC<CategoryTileProps> = ({
  category: {
    image: { url },
    name,
    slug
  }
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Link
      href={{
        pathname: `/shop/${slug}`,
        hash: 'product-list'
      }}
      passHref
    >
      <CustomA>
        <Box className={classes.container} component="article">
          <Image src={url} quality={100} width={390} height={390} />

          <Box className={classnames(classes.titleContainer, 'category-tile-title')}>
            <Typography variant="h4" component="h3" className={classes.title}>
              {name}
            </Typography>
          </Box>
        </Box>
      </CustomA>
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    '&:hover .category-tile-title': {
      backgroundColor: 'transparent'
    },
    '&:hover .category-tile-title h3': {
      color: theme.palette.common.white,
      fontWeight: 600
    }
  },
  titleContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    bottom: theme.spacing(6),
    minWidth: '70%',
    backgroundColor: theme.palette.common.white,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    transition: 'background-color 300ms',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap'
    }
  },
  title: {
    textTransform: 'uppercase',
    transition: 'color 300ms',
    fontWeight: 400,
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem'
    }
  }
}));

export default CategoryTileItem;
