import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { extraPalette } from 'theme/palette';
import SquareImage from 'components/ui/SquareImage';

interface Props {
  title: string;
  subtitle?: string;
  hasDivider?: boolean;
  icon?: any;
}

const FeatureItem: React.FC<Props> = ({
  title,
  subtitle = '',
  hasDivider = false,
  icon
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.contentContainer}>
      {icon && (
        <Box className={classes.imageContainer}>
          <SquareImage src={icon} />
        </Box>
      )}

      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>

      <Typography variant="h5" component="h6" className={classes.subtitle}>
        {subtitle}
      </Typography>

      {hasDivider && <Box className={classes.divider} />}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      height: 250
    }
  },
  imageContainer: {
    width: 50,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2)
    }
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  divider: {
    width: '80%',
    height: 1,
    backgroundColor: extraPalette.grey.main,
    opacity: 0.08,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: -theme.spacing(2),
      top: 0,
      width: 1,
      height: '100%'
    }
  }
}));

export default FeatureItem;
