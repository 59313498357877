import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
  title: string;
  children: React.ReactNode;
  dense?: boolean;
}

const HomeBlock: React.FC<Props> = ({ title, children, dense = false }): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box
        textAlign="center"
        className={classNames(classes.titleContainer, dense && classes.denceTitleContainer)}
      >
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </Box>

      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(17)
    }
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5.5)
    }
  },
  denceTitleContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2.5)
    }
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.875rem'
    }
  }
}));

export default HomeBlock;
