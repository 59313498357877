import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

interface TextContentProps {
  title: string;
  subtitle?: string;
}

const TextContent: React.FC<TextContentProps> = ({
  title,
  subtitle
}: TextContentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>

      {!!subtitle && (
        <Typography variant="h2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.75rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.75rem'
    }
  },
  subtitle: {
    color: theme.palette.common.white,
    fontWeight: 800,
    textTransform: 'uppercase',
    fontSize: '1.125rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.75rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem'
    }
  }
}));

export default TextContent;
